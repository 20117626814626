export const useKbbStore = defineStore('kbb', () => {
    // For ValidateAndValueVehicle endpoint
    const selectedCondition = ref<string | null>(null);
    const configuration = ref<KbbValidateAndValuesRequestConfigurationSettings>(
        { vehicleId: 0, vehicleOptionIds: [] }
    );

    // For both endpoints
    const arkona = ref<string | null>(null);
    const siteArkona = ref<string | null>(null);

    const vehicle = ref({
        type: null as string | null,
        year: null as number | null,
        make: null as string | null,
        model: null as string | null,
        trim: null as string | null,
        vin: null as string | null,
        stockNumber: null as string | null,
        mileage: null as number | null,
    });

    const customer = ref({
        firstName: null as string | null,
        lastName: null as string | null,
        phoneNumber: null as string | null,
        email: null as string | null,
        zipCode: null as string | null,
        bestTimeToContact: null as string | null,
        monthsToSell: null as string | null,
    });

    const values = ref<KbbValuesResponsePrice[]>([]);

    const interestedVehicle = ref({
        type: null as string | null,
        year: null as number | null,
        make: null as string | null,
        model: null as string | null,
        trim: null as string | null,
        vin: null as string | null,
        stockNumber: null as string | null,
    });

    const honeypot = ref('');

    type YmmCache = { data: FormFieldOption[]; selected: string };
    const cache = ref({
        trims: [] as (KbbVehiclesByVinResponseVinResult & FormFieldOption)[],
        vehicleDetails: null as KbbVehiclesResponseItemDetail | null,
        vehicleOptions: [] as KbbVehicleOptionsResponseItemDetail[],
        ymmYears: { data: [], selected: '' } as YmmCache,
        ymmMakes: { data: [], selected: '' } as YmmCache,
        ymmModels: { data: [], selected: '' } as YmmCache,
        ymmTrims: { data: [], selected: '' } as YmmCache,
        ymmVehicles: { data: [], selected: '' } as YmmCache,
    });

    const payoff = ref<KbbAcceptOfferRequestPayOffDetails>({
        amount: 0,
        lienHolder: '',
        monthlyPayment: null,
    });

    const ADD_VEHICLE_OPTION = (id: number) => {
        if (!configuration.value.vehicleOptionIds.includes(id)) {
            configuration.value.vehicleOptionIds.push(id);
        }
    };

    const REMOVE_VEHICLE_OPTION = (id: number) => {
        const indexToRemove = configuration.value.vehicleOptionIds.indexOf(id);

        if (indexToRemove !== -1) {
            configuration.value.vehicleOptionIds.splice(indexToRemove, 1);
        }
    };

    const RESET_CONFIGURATION = () => {
        configuration.value.vehicleId = 0;
        configuration.value.vehicleOptionIds = [];
    };

    return {
        selectedCondition,
        configuration,
        arkona,
        siteArkona,
        vehicle,
        customer,
        values,
        interestedVehicle,
        honeypot,
        cache,
        payoff,
        ADD_VEHICLE_OPTION,
        REMOVE_VEHICLE_OPTION,
        RESET_CONFIGURATION,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useKbbStore, import.meta.hot));
}
